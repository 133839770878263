import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).token : '';
        let changedRequest = req;
        // HttpHeader object immutable - copy values
        const headerSettings: { [name: string]: string | string[]; } = {};

        for (const key of req.headers.keys()) {
            headerSettings[key] = req.headers.getAll(key);
        }

        if (token !== '' && req.url !== 'https://api.ipify.org?format=json') {
            headerSettings['Authorization'] = token;
            if (!(req.body instanceof FormData)) {
                headerSettings['Content-Type'] = 'application/json';
            }
            const newHeader = new HttpHeaders(headerSettings);

            changedRequest = req.clone({
                headers: newHeader
            });
            return next.handle(changedRequest).pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        console.log('all looks good');
                        // http response status code
                        console.log(event.status);
                    }
                }, error => {
                    // http response status code
                    if (error.status === 0) {
                        this._router.navigate(['/Error/ServerError']);
                    }
                    if (error.status === 403) {
                        this._router.navigate(['/Error/NotAuthorized']);
                    }
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
