import { Injectable, EventEmitter } from '@angular/core';

import { JwtHelper } from '../helpers/jwt-helper';

@Injectable()
export class UserService {

    // currentUser;

    userImageUpdated = new EventEmitter<string>();

    constructor(private jwt: JwtHelper) {
        // const user = this.jwt.decodeToken();
        // this.currentUser = user ? user : null;
    }

    isLoggedIn() {
        try {
            const token = JSON.parse(localStorage.getItem('currentUser'));
            if (token !== null && !this.jwt.isTokenExpired()) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}
