import { Injectable } from '@angular/core';
import { Router, CanLoad, Route } from '@angular/router';

import { JwtHelper } from '../helpers/jwt-helper';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGuard implements CanLoad {
    constructor(
        private router: Router,
        private jwt: JwtHelper
    ) { }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        const expectedRole = route.data.ExpectedRole;

        const tokenPayload = this.jwt.decodeToken();

        if (!tokenPayload.Roles.includes(expectedRole)) {
            this.router.navigate(['/Error/NotAuthorized']);
            return false;
        }
        return true;
    }
}
