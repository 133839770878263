import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './theme/layouts/main-layout/main-layout.component';
import { EmptyLayoutComponent } from './theme/layouts/empty-layout/empty-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { Role } from './shared/enums/Role.enum';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'account',
    component: EmptyLayoutComponent,
    data: {
      title: 'account'
    },
    children: [
      {
        path: '',
        loadChildren: './account/account.module#AccountModule'
      }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: { title: 'Home' },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: '../app/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'profile',
        loadChildren: '../app/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: 'stores',
        data: { ExpectedRole: Role.StoresPage },
        loadChildren: '../app/requests/requests.module#RequestsModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'addrequest',
        data: { ExpectedRole: Role.AddRequestPage },
        loadChildren: '../app/add-request/add-request.module#AddRequestModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'returnstickers',
        data: { ExpectedRole: Role.ReturnStickersPage },
        loadChildren: '../app/return-sticker/return-sticker.module#ReturnStickerModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'export-sticker',
        data: { ExpectedRole: Role.ExportRequestPage },
        loadChildren: '../app/export-stickers/export-stickers.module#ExportStickersModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'producttypes',
        data: { ExpectedRole: Role.ProductTypesPage },
        loadChildren: '../app/product-type/product-type.module#ProductTypeModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'printing',
        data: { ExpectedRole: Role.PrintingPage },
        loadChildren: '../app/printing/printing.module#PrintingModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'users',
        data: { ExpectedRole: Role.UsersPage },
        loadChildren: '../app/users/users.module#UsersModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'reports',
        data: { ExpectedRole: Role.ReportsPages },
        loadChildren: '../app/reports/reports.module#ReportsModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'privileges',
        data: { ExpectedRole: Role.PrivilegesPage },
        loadChildren: '../app/control-panel/control-panel.module#ControlPanelModule',
        canLoad: [RoleGuard]
      },
      {
        path: 'Error',
        loadChildren: '../app/error-pages/error-pages.module#ErrorPagesModule'
      },
      {
        path: '**',
        loadChildren: '../app/error-pages/error-pages.module#ErrorPagesModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, RoleGuard]
})
export class AppRoutingModule { }
