export enum Role {
    AddRequestPage = 1,
    ApproveRequestButton,
    PrintingPage,
    ReprintButton,
    UnlockButton,
    StoresPage,
    StoreButton,
    DispenceButton,
    ReturnStickersPage,
    ReportsPages,
    ProductTypesPage,
    UsersPage,
    AddGroupButton,
    DeleteGroupButton,
    PrivilegesPage,
    ExportRequestPage,


}
