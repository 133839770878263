import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiConnectionService {

    constructor(private http: HttpClient) { }

    private getFullUrl(url: string): string {
        if (url.charAt(0) === '/') {
            url = url.substring(1);
        }
        return environment.endpoint + url;
    }

    // private handleError(errorResponse: HttpErrorResponse) {
    //     if (errorResponse.error instanceof ErrorEvent) {
    //         console.log('Client Side Error : ' + errorResponse.error);
    //     } else {
    //         console.log('Server Side Error : ');
    //         console.log(errorResponse);
    //     }

    //     return 'Server Error';
    // }

    public ServerRequest(URL: string, Method: string, Data: any): Observable<Object> {

        switch (Method) {
            case 'POST':
                return this.http.post(this.getFullUrl(URL), Data);
            case 'post':
                return this.http.post(this.getFullUrl(URL), Data);
            case 'GET':
                return this.http.get(this.getFullUrl(URL));
            case 'get':
                return this.http.get(this.getFullUrl(URL));
            case 'PUT':
                return this.http.put(this.getFullUrl(URL), Data);
            case 'put':
                return this.http.put(this.getFullUrl(URL), Data);
            case 'DELETE':
                return this.http.delete(this.getFullUrl(URL));
            case 'delete':
                return this.http.delete(this.getFullUrl(URL));
            default:
                break;
        }
    }

    public GetIpAddress() {
        return this.http.get('https://api.ipify.org?format=json');
    }
}
