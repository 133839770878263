import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    register(fullName: string, email: string, password: string, groupID: string) {
        return this.http.post(
            environment.endpoint + 'api/register',
            { FullName: fullName, Email: email, Password: password, GroupID: groupID }
        );
    }

    login(userName: string, password: string): Observable<Object> {
        return this.http.post(
            environment.endpoint + 'token',
            { UserName: userName, Password: password }
        );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
    }
}
