import { Component, OnInit, Input } from '@angular/core';

import { NbSidebarService, NbMenuService } from '@nebular/theme';
import { JwtHelper } from '../../../shared/helpers/jwt-helper';
import { UserService } from '../../../shared/services/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any = {};

  userMenu = [{ title: 'Profile', link: '/profile' }, { title: 'Log out', link: '/account/login' }];

  headerLogo = 'logo-big.png';

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private jwt: JwtHelper,
    private userService: UserService
  ) { }

  ngOnInit() {

    const updatedImage = localStorage.getItem('ImagePath');
    if (updatedImage) {
      this.user.picture = environment.endpoint + updatedImage;
    } else {
      const decodedToken = this.jwt.decodeToken();
      if (decodedToken.ImagePath !== null) {
        this.user.picture = environment.endpoint + decodedToken.ImagePath;
      }
    }

    this.user.name = this.jwt.decodeToken() ? this.jwt.decodeToken().UserName : '';

    this.userService.userImageUpdated.subscribe(
      (imagePath) => {
        this.user.picture = environment.endpoint + imagePath;
      });
  }

  toggleSidebar(): boolean {
    if (this.headerLogo === 'logo-big.png') {
      this.headerLogo = 'logo.png';
    } else {
      this.headerLogo = 'logo-big.png';
    }
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

}
