import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

import { JwtHelper } from '../../../shared/helpers/jwt-helper';
import { Role } from '../../../shared/enums/Role.enum';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  AccessedMenu: NbMenuItem[] = [
    {
      title: 'Dashboard',
      icon: 'nb-home',
      link: '/',
      home: true,
    },
    {
      title: 'Transactions',
      icon: 'ion-arrow-swap',
      children: [
        {
          title: 'Add Request',
          data: Role.AddRequestPage,
          icon: 'nb-edit',
          link: '/addrequest'
        },
        {
          title: 'Printing',
          data: Role.PrintingPage,
          icon: 'fa fa-print',
          link: '/printing'
        },
        {
          title: 'Stores',
          data: Role.StoresPage,
          icon: 'nb-paper-plane',
          link: '/stores'
        },
        {
          title: 'Return Stickers',
          data: Role.ReturnStickersPage,
          icon: 'ion-log-in',
          link: '/returnstickers'
        },
        {
          title: 'Export Stickers',
          data: Role.ExportRequestPage,
          icon: 'nb-compose',
          link: '/export-sticker'
        },
      ]
    },
    {
      title: 'Reports',
      data: Role.ReportsPages,
      icon: 'nb-compose',
      children: [
        {
          title: 'Verified Stickers',
          icon: 'ion-clipboard',
          link: '/reports/usedstickers'
        },
        {
          title: 'Expired Stickers',
          icon: 'ion-clipboard',
          link: '/reports/expiredstickers'
        },
        {
          title: 'Repeated Stickers',
          icon: 'ion-clipboard',
          link: '/reports/repeatedstickers'
        },
        {
          title: 'Not Exist Serial Stickers',
          icon: 'ion-clipboard',
          link: '/reports/notexistserial'
        },
        {
          title: 'Request Status',
          icon: 'ion-clipboard',
          link: '/reports/requeststatus'
        },
        {
          title: 'Production Quantity',
          icon: 'ion-clipboard',
          link: '/reports/productionquantity'
        },
        {
          title: 'Returned Count',
          icon: 'ion-clipboard',
          link: '/reports/returnedcount'
        },
        {
          title: 'Reprinted Stickers',
          icon: 'ion-clipboard',
          link: '/reports/reprintedstickers'
        }
      ]
    },
    {
      title: 'Control Panel',
      icon: 'ion-settings',
      children: [
        {
          title: 'Product Types',
          data: Role.ProductTypesPage,
          icon: 'ion-filing',
          link: '/producttypes'
        },
        {
          title: 'Users',
          data: Role.UsersPage,
          icon: 'nb-person',
          link: '/users'
        },
        {
          title: 'Privileges',
          data: Role.PrivilegesPage,
          icon: 'nb-locked',
          link: '/privileges'
        }
      ]
    }
  ];

  constructor(
    private jwt: JwtHelper
  ) { }

  ngOnInit() {
    const decodedToken = this.jwt.decodeToken();
    if (decodedToken) {
      const allRoles: number[] = decodedToken.Roles;
      console.log(allRoles , "allRoles")
      this.AccessedMenu = this.AccessedMenu.filter((v, i) => {
        if (v.data === undefined) {
          if (v.children !== undefined) {
            v.children = v.children.filter((child, index) => {
              if (allRoles.includes(child.data)) {
                return child;
              }
            });
            if (v.children.length > 0) {
              return v;
            }
          } else {
            return v;
          }
        } else {
          if (allRoles.includes(v.data)) {
            return v;
          }
        }
      });
    }
  }
}
